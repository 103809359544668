//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
import Location from "@/components/Location";
export default {
  components: {
    Location,
  },
  watch: {
    searchText: "noName",
    arrays: "arrMonitor",
    result(newVal, oldVal) {
      if (this.showLength != null && this.showLength < newVal.length) {
        this.ShowObj = newVal.filter((item, index) => {
          return index < this.showLength;
        });
      } else {
        this.ShowObj = newVal;
      }
    },
  },
  props: {
    //团队id
    teamId: {
      type: Number,
      default: null,
    },
    //是否显示选中人
    showActive: {
      type: Boolean,
      default: true,
    },
    //是否显示选中人的头像
    showActiveImg: {
      type: Boolean,
      default: true,
    },
    //是否显示选中人的名字
    showActiveName: {
      type: Boolean,
      default: true,
    },
    //是否多选
    isSelection: {
      type: Boolean,
      default: true,
    },
    //默认选中人
    arrays: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //默认选项中名字
    defaultName: {
      type: String,
      default: "",
    },
    //是否只读
    readonly: {
      type: Boolean,
      default: false,
    },
    // 自定义弹出位置，基于窗口 例:{offLeft:'10%',offRight:'20px'} 这几个属性选填(offTop,offLeft,offRight,offBottom)
    options: {
      type: Object,
      default: null,
    },
    //头像大小
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["medium", "small"].indexOf(value) !== -1;
      },
    },
    //选择范围
    selRange: {
      type: Number,
      default: 0,
    },
    selRangeStatu: {
      type: Number,
      default: 1,
    },
    //@功能
    openAt: {
      type: Boolean,
      default: false,
    },
    showLength: {
      type: Number,
      default: null,
      validator: function (value) {
        return value > 0 || value == null;
      },
    },
    userRoleItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      reverse: false, //反选
      all: false, //全选
      checkedCities: [], //选中的成员
      position: null, //职位
      pageData: null, //页数据
      loading: false, //加载
      searchText: "", //搜索成员
      isSearch: false, //标识是否在搜索
      isSearching: false, //标识是否在搜索中
      searchData: [], //搜索的数据
      showCollapse: [], //取消手风琴
      result: [], //返回数据
      defalutId: null, //默认展开的选项
      ShowObj: [], //需要显示的人员长度
    };
  },
  methods: {
    imgChange,
    //部门全选
    selectGroup(index) {
      let flag = document.getElementById(`${index}`).checked;
      if (!flag) {
        this.pageData[index].UsersData.forEach((group) => {
          if (this.checkedCities.indexOf(group.UsId) == -1) {
            this.checkedCities.push(group.UsId);
          }
        });
      } else {
        this.pageData[index].UsersData.forEach((group) => {
          for (let i = 0; i < this.checkedCities.length; i++) {
            if (group.UsId == this.checkedCities[i]) {
              this.checkedCities.splice(i, 1);
              break;
            }
          }
        });
      }
    },
    //组件的关闭方法
    childColse(val) {
      //取消前的方法
      this.$emit("Cancel");
      this.$refs.child.isShow(val);
    },
    //组件的打开方法
    childOpen(event) {
      this.$refs.child.open(event);
    },
    //加载数据
    beforeOpen() {
      this.getTeamData(); //不能查所有用户
      // if (this.teamId) {
      //   this.getTeamData();
      // } else {
      //   this.getData();
      // }

      this.defalutId = 0;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    //单选
    checkChange(val) {
      if (!this.isSelection) {
        this.checkedCities = [];
        this.checkedCities.push(val.pop());
      }
    },
    getData() {
      this.defalutId = 0;
      this.loading = true;
      this.$http
        .get("/General/memberSelector.ashx", {
          params: {
            name: this.searchText,
          },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.pageData = resp.data;
            this.loading = false;
          }
        });
      this.$refs.scroll.scrollTop = 0;
      this.isSearch = false;
      this.searchText = "";
    },
    getTeamData() {
      this.defalutId = 0;
      this.loading = true;
      const data = {
        teamId: this.teamId,
        Ids: [],
      };
      this.$http
        .post("/Management/TeamManagement/MenberSelectsByDept.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            //去除没有人员的部门
            _.remove(resp.data, function (item) {
              return item.UserTotal == 0;
            });
            resp.data.forEach((group) => {
              group.UsersData = group.UsersData.map((m) => {
                return {
                  Identity: m.Identity,
                  UsId: m.UserId,
                  Name: m.Name,
                  Name_Pinyin: m.UPinYin,
                  Picture: m.Picture,
                  MType: m.MType,
                  ClientStatus: m.ClientStatus,
                };
              });
            });
            this.pageData = resp.data;
            this.loading = false;
          }
        });
      this.$refs.scroll.scrollTop = 0;
      this.isSearch = false;
      this.searchText = "";
    },
    //确认选择
    confirm() {
      this.$emit("getUser", this.userRoleItem);
      this.result = [];
      var temp = [];
      if (this.isSelection) {
        let allUsers = [];
        this.pageData.forEach((m) => allUsers.push(...m.UsersData));
        for (let i = 0; i < this.checkedCities.length; i++) {
          for (let j = 0; j < allUsers.length; j++) {
            let user = allUsers[j];
            if (
              this.checkedCities[i] == user.UsId &&
              temp.indexOf(this.checkedCities[i]) === -1
            ) {
              temp.push(this.checkedCities[i]);
              this.result.push(user);
              break;
            }
          }
        }
      } else {
        let allUsers = [];
        this.pageData.forEach((m) => allUsers.push(...m.UsersData));
        for (let j = 0; j < allUsers.length; j++) {
          let user = allUsers[j];
          if (this.checkedCities[0] == user.UsId) {
            this.result.push(user);
            break;
          }
        }
      }
      this.childColse(false);
      //返回数据给父组件
      this.$emit("Confirm", this.result);
      if (this.openAt) this.clearValue();
    },
    //反选
    reversechange(val) {
      this.all = false;
      let arr = [];
      for (let i = 0; i < this.pageData.length; i++) {
        for (let j = 0; j < this.pageData[i].UsersData.length; j++) {
          arr.push(this.pageData[i].UsersData[j].UsId);
        }
      }
      for (let a = 0; a < this.checkedCities.length; a++) {
        for (let r = 0; r < arr.length; r++) {
          if (this.checkedCities[a] == arr[r]) {
            arr.splice(r, 1);
          }
        }
      }
      this.checkedCities = Array.from(new Set(arr));
    },
    //全选
    allchange(val) {
      this.reverse = false;
      if (val) {
        for (let i = 0; i < this.pageData.length; i++) {
          for (let j = 0; j < this.pageData[i].UsersData.length; j++) {
            if (
              this.checkedCities.indexOf(this.pageData[i].UsersData[j].UsId) ===
              -1
            ) {
              this.checkedCities.push(this.pageData[i].UsersData[j].UsId);
            }
          }
          if (this.pageData[i].UserTotal > 0) {
            document.getElementById(`${i}`).checked = true;
          }
        }
      } else {
        this.checkedCities = [];
        for (let i = 0; i < this.pageData.length; i++) {
          document.getElementById(`${i}`).checked = false;
        }
      }
    },
    //删除
    deluser(index, e) {
      this.$emit("getUser", this.userRoleItem);
      if (!this.readonly) {
        if (e.target.localName === "li") {
          this.checkedCities.splice(index, 1);
          this.result.splice(index, 1);
          //返回数据给父组件
          this.$emit("Confirm", this.result);
        }
      }
    },
    //搜索成员,自己查找
    search() {
      let text = _.trim(this.searchText);
      if (!text || this.pageData == null) {
        return;
      }
      this.isSearch = true;
      if (!this.isSearching) {
        this.searchData = [];
        this.showCollapse = [];
        this.isSearching = true;
        this.pageData.map((parent) => {
          let userData = [];
          parent.UsersData.map((child) => {
            if (
              child.Name.indexOf(text) != -1 ||
              (child.Name_Pinyin && this.$checkPinyin(text, child.Name_Pinyin))
            ) {
              userData.push(child);
            }
          });
          if (userData.length > 0) {
            let result = Object.assign({}, parent);
            result.UsersData = userData;
            result.UserTotal = userData.length;
            this.searchData.push(result);
            this.showCollapse.push(result.Id);
          }
        });
        this.isSearching = false;
      }
    },
    //删除搜索内容
    noName(val) {
      if (!val) {
        this.isSearch = false;
      }
    },
    //默认显示头像
    arrMonitor() {
      // 2020-01-21 CX 改
      this.checkedCities = [];
      this.result = [];
      //单选
      if (!this.isSelection) {
        if (this.checkedCities.length == 0) {
          this.arrays.forEach((item) => {
            if (item != null && this.checkedCities.indexOf(item.UsId) == -1) {
              this.checkedCities.push(item.UsId);
              this.result.push(item);
            }
          });
        }
      } else {
        this.arrays.forEach((item) => {
          if (item != null && this.checkedCities.indexOf(item.UsId) == -1) {
            this.checkedCities.push(item.UsId);
            this.result.push(item);
          }
        });
      }
    },
    isTask() {
      let a = window.location.href;
      const i = a.indexOf("task");
      if (i > -1) {
        let reg = /^task\/[0-9]*\/[0-9]*/;
        return reg.test(a.substring(i));
      } else {
        return false;
      }
    },
    //清除选择数据
    clearValue() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  mounted() {
    this.arrMonitor();
  },
  beforeDestroy() {
    this.$refs.child.isShow(false);
  },
};
